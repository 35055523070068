* {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Rubik', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.reveal {
  opacity: 0;
  -webkit-transform: translate3d(0, 100px, 0);
  transform: translate3d(0, 100px, 0);
  -webkit-transition: opacity 0.8s, -webkit-transform 0.8s;
  transition: opacity 0.8s, -webkit-transform 0.8s;
  transition: opacity 0.8s, transform 0.8s;
  transition: opacity 0.8s, transform 0.8s, -webkit-transform 0.8s;
}

.reveal_visible {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

header {
  font-size: 16px;
  font-weight: 300;
  padding-top: 30px;
  margin-bottom: 120px;
  background-color: white;
}

header .nav-item {
  padding-right: 15px;
}

header .navbar-btn a {
  text-decoration: none;
}

header .navbar-btn .btn-1 {
  color: #0B132A;
  font-weight: 400;
  margin-right: 12px;
}

header .navbar-btn .btn-fill {
  border: 1px solid #287fdc;
  border-radius: 50px;
  color: #287fdc;
  padding: 10px 20px;
}

header .navbar-light .navbar-toggler {
  color: #287fdc;
  border: none;
}

/* Banner section */
.banner {
  margin-top: 95px;
}

.banner .content-box h1 {
  line-height: 50px;
  padding-bottom: 25px;
}

.banner .content-box p {
  line-height: 30px;
  font-size: 18px;
  margin-bottom: 80px;
  font-weight: 300;
  color: #4f5665b7;
}

.banner .content-box .button-fill {
  margin: 50px 0 35px 0;
}

.banner .content-box .button-fill a {
  background-color: #287fdc;
  text-decoration: none;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 10px;
}

.banner .user-count {
  margin: 100px 0;
  -webkit-box-shadow: 4px 10px 7px 5px rgba(109, 108, 108, 0.027);
  box-shadow: 4px 10px 7px 5px rgba(109, 108, 108, 0.027);
}

.banner .user-count .count-box {
  padding: 60px;
}

.banner .user-count .count-box .icon {
  background-color: #FFECEC;
  color: #287fdc;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 50px;
}

.banner .user-count .count-box .count-item #counters_1, .banner .user-count .count-box .count-item #counters_2, .banner .user-count .count-box .count-item #counters_3 {
  font-size: 25px;
  font-weight: 700;
}

.banner .user-count .count-box .count-item p {
  color: #4f5665b7;
}

.features {
  margin: 100px 0;
}

.features .illustration {
  margin-right: 50px;
}

.features .content-box h1 {
  line-height: 50px;
  padding-bottom: 25px;
}

.features .content-box p {
  color: #4f5665b7;
  font-size: 16px;
}

.features .content-box .list-group-flush > .list-group-item {
  border-width: 0 !important;
}

.features .content-box .f-list li .icon {
  margin-right: 10px;
  background-color: #2FAB73;
  color: white;
  height: 30px;
  width: 30px;
  padding: 2px;
  border-radius: 50%;
}

.pricing {
  margin: 200px 0;
}

.pricing .content {
  margin-bottom: 25px;
}

.pricing .content h1 {
  margin-bottom: 15px;
}

.pricing .content h5 {
  font-weight: 300;
  color: #4f5665b7;
  margin-bottom: 40px;
}

.pricing .pricing-cards .plans {
  padding-bottom: 20px;
}

.pricing .pricing-cards .plans .card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: #4f5665b7 2px solid;
  border-radius: 15px;
  margin: auto;
}

.pricing .pricing-cards .plans .card img {
  width: 50%;
  padding: 50px 0 10px 0;
}

.pricing .pricing-cards .plans .card .card-body {
  text-align: center;
  margin-bottom: 12px;
}

.pricing .pricing-cards .plans .card .card-body .list-group-flush > .list-group-item {
  border-width: 0 !important;
}

.pricing .pricing-cards .plans .card .card-body .list-group-item {
  padding: 0 !important;
}

.pricing .pricing-cards .plans .card .card-body li {
  color: #4f5665b7;
  font-weight: 300;
}

.pricing .pricing-cards .plans .card .card-body li .icon {
  margin-right: 10px;
  color: #2FAB73;
  height: 30px;
  width: 30px;
  padding: 6px;
  border-radius: 50%;
}

.pricing .pricing-cards .plans .card h5 {
  padding: 20px 0 30px 0;
  font-weight: 700;
}

.pricing .pricing-cards .plans .card h5 span {
  font-weight: 400;
  color: #4f5665b7;
}

.pricing .pricing-cards .plans .card .btn-subscribe {
  border: 2px solid #287fdc;
  padding: 12px 40px;
  color: #287fdc;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 20px;
  -webkit-transition: all 200ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 200ms cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pricing .pricing-cards .plans .card .btn-subscribe:hover {
  background-color: #287fdc;
  color: white;
}

.pricing .pricing-cards .plans .active {
  border-radius: #287fdc;
}

.location h5 {
  font-weight: 300;
  color: #4f5665b7;
  margin: 30px 0 50px 0;
}

.location .map {
  padding: 50px 0;
}

.clients {
  margin: 100px 0;
}

.clients img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.clients img:hover {
  -webkit-filter: grayscale(1%);
  filter: grayscale(1%);
  cursor: pointer;
}

.testimonials {
  margin: 150px 0;
}

.testimonials h5 {
  font-weight: 300;
  color: #4f5665b7;
  margin: 30px 0 50px 0;
}

.testimonials .testimonial-cards {
  margin-top: 100px;
}

.testimonials .testimonial-cards .card {
  padding: 15px;
  border-radius: 15px;
  border: 2px solid #4f5665b7;
}

.testimonials .testimonial-cards .card .row {
  margin-bottom: 20px;
}

.testimonials .testimonial-cards .card .row img {
  width: 60%;
}

.testimonials .testimonial-cards .card .row h5 {
  margin: 0;
  font-weight: 500;
  color: #0B132A;
}

.testimonials .testimonial-cards .card .row p {
  font-size: 18px;
}

.testimonials .testimonial-cards .card .row .icon {
  color: #FEA250;
  padding: 3px;
}

.testimonials .testimonial-cards .card .row .text-muted {
  font-size: 15px;
  font-weight: 300;
}

.testimonials .testimonial-cards .card p {
  font-size: 16px;
  color: #0B132A;
  line-height: 30px;
}

.testimonials .testimonial-cards .card:hover {
  border-radius: 15px;
  border: 2px solid #287fdc;
  -webkit-transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1);
  cursor: pointer;
}

footer {
  background-color: #F8F8F8;
}

footer .subscribe {
  background-color: white;
  -webkit-box-shadow: 4px 10px 7px 5px rgba(109, 108, 108, 0.027);
  box-shadow: 4px 10px 7px 5px rgba(109, 108, 108, 0.027);
  border-radius: 5px;
  position: relative;
  top: -90px;
}

footer .subscribe .row {
  padding: 50px;
}

footer .subscribe .row h5 {
  font-weight: 300;
  color: #4f5665b7;
}

footer .subscribe .row .btn-subscribe {
  margin: 80px 0 50px 0;
}

footer .subscribe .row .btn-subscribe a {
  background-color: #287fdc;
  text-decoration: none;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 10px;
  -webkit-box-shadow: 4px 5px 5px 2px rgba(247, 45, 45, 0.13);
  box-shadow: 4px 5px 5px 2px rgba(40, 127, 220, 0.13);
}

footer .footer-links {
  margin-bottom: 15px;
}

footer .footer-links .col-right .footer-logo {
  margin-bottom: 25px;
}

footer .footer-links .col-right h5 {
  color: #4f5665b7;
  margin-bottom: 50px;
}

footer .footer-links .col-right span {
  font-weight: 300;
  color: #4f5665b7;
}

footer .footer-links .col-right .social-icons {
  margin-bottom: 30px;
}

footer .footer-links .col-right .social-icons .icon {
  color: #287fdc;
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 5px;
  padding: 10px;
  -webkit-box-shadow: 4px 2px 3px rgba(247, 79, 79, 0.144);
  box-shadow: 4px 2px 3px rgba(247, 79, 79, 0.144);
}
.collapse {
  transition: height .35s ease, opacity 0.35s ease!important;
}
.collapse:not(.show){
  /*display: block!important;*/
  opacity: 0;
}
footer .footer-links .col-left h5 {
  margin-bottom: 20px;
}

footer .footer-links .col-left .links li {
  list-style: none;
  color: #4f5665b7;
  font-weight: 300;
  padding-bottom: 15px;
}

footer .footer-links .col-left .links li:hover {
  cursor: pointer;
  color: #287fdc;
  -webkit-transition: ease-in-out;
  transition: ease-in-out;
}

footer .attribution a {
  text-decoration: none;
  color: #287fdc;
}
.index-slide-img{
  width: 150px!important;
}
.clients .slick-slide{
  width: 150px!important;
  margin-left: 50px;
}

.feature{
  max-width: 1280px;
  width: 100%;
  margin: auto;
  position: relative;
}
.footer-dropdown{
  position: relative;
}
.footer-dropdown-menu{
  position: absolute;
  bottom: 100%;
  left: 0;
  background-color: #222;
  border: 1px solid #666;
  border-radius: 5px;
  width: 100%;
  z-index: 1000;
}
.footer-dropdown-menu a {
  color: white;
  padding: 10px 20px;
  display: block;
  textDecoration: none;
  whiteSpace: nowrap;
}
/*.page1-box-wrapper {
  !*margin-bottom: 62px;*!
  display: flex;
  align-items: flex-start;
  padding-left: 0;
}*/
/*.page1-box-wrapper li {
  width: 33.33%;
  display: inline-block;
  will-change: transform;
}*/
.page1-box-wrapper .page1-box {
  /*width: 194px;*/
  /*margin: auto;*/
  text-align: center;
  position: relative;
}
.page1-point-wrapper {
  position: absolute;
  width: 0;
  left: 50%;
  top: 0;
}
.page1-box-wrapper .page1-image {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin: 20px auto 32px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  transition: box-shadow .3s ease-out, transform .3s ease-out;
}
.page1-box-wrapper .page1-box:hover .page1-image {
  /*transform: translateY(-5px);*/
}
.page1-box-wrapper .page1-box h3 {
  color: #0d1a26;
  font-size: 16px;
  margin: 8px auto;
}
.page1-image{
  box-shadow: rgba(114, 46, 209, 0.12) 0px 6px 12px;
}
.page1-point-wrapper .point-0{
  width: 4px;
  height: 4px;
}
.page1-point-wrapper i{
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  opacity: 0;
  transform: translateY(30px);
}
.page1-point-wrapper .point-ring{
  border-style: solid;
  border-width: 1px;
  background: transparent!important;
}
.page1-point-wrapper .point-2, .page1-point-wrapper .point-ring{
  width: 10px;
  height: 10px;
}
.page1-point-wrapper .point-1{
  width: 6px;
  height: 6px;
}
.page1-point-wrapper .point-3{
  width: 15px;
  height: 15px;
}
.footer-new{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  width: 100%;
}
.footer-flex{
  flex: 1;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .footer-new{
    display: block;
    padding: 10px 0;
  }
  .footer-flex {
    display: block;
    width: 100%;
    flex: unset;
    text-align: center;
  }
  .footer-user{
    display: block;
    width: 100%;
  }

  .footer-nav a {
    padding: 5px 0;
  }

  .container .subscribe .row div {
    flex: 100%;
    text-align: center;
  }

  .btn-subscribe a {
    display: block;
  }
}

@media (min-width: 992px){
  .collapse {
    display: block!important;
    opacity: 1!important;
    height: auto!important;
  }
}
